import { client } from "@/index";
import { userChangeSite } from "@actions";
import { useDebounce, useDidMountEffect, useSearch, useViewport } from "@hooks";
import {
  AutoComplete,
  Box,
  Loader,
  Stack,
  StyledTextField,
  Text,
  TextField,
} from "@includes";
import { potionsLocalStorage } from "@localStorage";
import { Divider, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { RootState } from "@store";
import { arrayUtils } from "@utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShortUniqueId from "short-unique-id";
import { CustomPaper } from "./components/CustomPaper";
import { DEFAULT_INPUT_WIDTH, FONT_SIZE } from "./enums";
import { SEARCH_ACCOUNT } from "./requests";

const SiteSelector = () => {
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const theme = useTheme();
  const uid = new ShortUniqueId();

  const siteId = useSelector((state: RootState) => state.site.siteId);
  const siteName = useSelector((state: RootState) => state.site.siteName);
  const accountName = useSelector(
    (state: RootState) => state.account.accountName
  );
  const isSuperUser = useSelector(
    (state: RootState) => state.login.isSuperUser
  );
  const accountId = useSelector((state: RootState) => state.account.accountId);

  const [isOpen, setIsOpen] = useState(false);

  const [options, setOptions] = useState<Array<any>>([]);

  const [inputValueHasChanged, setInputValueHasChanged] = useState(false);

  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const [search, setSearch] = useState(inputValue);

  useDidMountEffect(() => {
    setInputValueHasChanged(true);
  }, [inputValue]);

  useEffect(() => {
    if (isOpen) setInputValueHasChanged(false);
  }, [isOpen]);

  const handleSearch = (term: string) => {
    return new Promise((resolve) => {
      client
        .query({
          query: SEARCH_ACCOUNT,
          variables: {
            search: term,
            first: 10000,
          },
        })
        .then((res) => {
          setOptions([
            ...res.data?.companies?.edges
              ?.map((item: Dic<Dic<string | any>>) => ({
                name: item?.node?.name,
                id: item?.node?.id,
                theme: item?.node?.theme,
                sites: item?.node?.sites?.edges?.map(
                  (subItem: Dic<Dic<string>>) => ({
                    id: subItem?.node?.id,
                    name: subItem?.node?.name,
                    isUnderMaintenance: subItem?.node?.isUnderMaintenance,
                  })
                ),
              }))
              ?.map((c: any) =>
                c?.sites?.map((s: any) => ({
                  ...s,
                  accountId: c?.id,
                  accountName: c?.name?.toLowerCase(),
                  accountTheme: c?.theme,
                }))
              )
              ?.flat(),
          ]);
          resolve([
            ...res.data?.companies?.edges?.map(
              (item: Dic<Dic<string | any>>) => ({
                name: item?.node?.name,
                id: item?.node?.id,
                theme: item?.node?.theme,
                sites: item?.node?.sites?.edges?.map(
                  (subItem: Dic<Dic<string>>) => ({
                    id: subItem?.node?.id,
                    name: subItem?.node?.name,
                    isUnderMaintenance: subItem?.node?.isUnderMaintenance,
                  })
                ),
              })
            ),
          ]);
        });
    });
  };

  const { searchResult: accounts, loading } = useSearch(
    inputValueHasChanged ? search : "",
    (term: string) => handleSearch(term)
  );

  const sites = useMemo(
    () =>
      arrayUtils.uniqueArray([
        ...accounts
          ?.map((c: any) =>
            c?.sites?.map((s: any) => ({
              ...s,
              accountId: c?.id,
              accountTheme: c?.theme,
              accountName: c?.name?.toLowerCase(),
            }))
          )
          ?.flat(),
        {
          accountId,
          accountName,
          id: siteId,
          name: siteName,
          isUnderMaintenance: false,
        },
      ]),
    [accounts, isOpen]
  );

  useEffect(() => {
    if (siteId && isOpen && !loading) handleSearch("");
  }, [siteId, isOpen]);

  useDebounce(
    () => {
      setSearch(inputValue);
    },
    [inputValue],
    600
  );

  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  const handleSelectChange = (value: any) => {
    if (value.toString().length !== 1) {
      setInputWidth(
        20 + value.toString().length * FONT_SIZE < DEFAULT_INPUT_WIDTH
          ? DEFAULT_INPUT_WIDTH
          : 20 + value.toString().length * FONT_SIZE
      );
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  };

  const handleChange = (value: any) => {
    if (value) {
      const {
        accountId,
        id,
        name,
        accountName,
        accountTheme,
        hasAlgorithmBuilder,
      } = value;
      if (!!accountId && !!id) {
        potionsLocalStorage.set("accountId", accountId);
        potionsLocalStorage.set("siteId", id);
        potionsLocalStorage.set("accountName", accountName);
        potionsLocalStorage.set("accountTheme", accountTheme);
        potionsLocalStorage.set("siteName", name);
        potionsLocalStorage.set("hasAlgorithmBuilder", true);
        dispatch(
          userChangeSite(
            siteId,
            name,
            accountId,
            accountName,
            accountTheme,
            hasAlgorithmBuilder
          )
        );
        window.location.href = `${window.location.origin}/${accountId}/${id}/home`;
      }
    }
  };

  useEffect(() => {
    if (siteName) handleSelectChange(siteName);
  }, [siteName]);

  useEffect(() => {
    if (sites?.length > 0 && selectedValue?.length === 0) {
      setSelectedValue(sites?.find((s: any) => s?.id === siteId) ?? []);
      setInputValue(sites?.find((s: any) => s?.id === siteId)?.name ?? "");
    }
  }, [sites]);

  const optionsSortedNySiteName = useMemo(
    () => options.sort(arrayUtils.dynamicSort("name")),
    [options]
  );

  if (loading && !sites) return <Loader />;

  return (
    <Box
      sx={{
        "& fieldset": { borderRadius: 1 },
      }}
    >
      <AutoComplete
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        filterOptions={(x) => x}
        autoHighlight
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && e.target.value) handleChange(e.target.value);
        }}
        PaperComponent={
          CustomPaper as unknown as React.JSXElementConstructor<
            React.HTMLAttributes<HTMLElement>
          >
        }
        onChange={(e: React.SyntheticEvent, value: any) => {
          if (!value?.isUnderMaintenance || isSuperUser) handleChange(value);
        }}
        groupBy={(option: any) => option?.accountId}
        value={selectedValue}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        noOptionsText=""
        loadingText=""
        loading={loading}
        disableClearable
        id="site-selector"
        options={optionsSortedNySiteName}
        getOptionLabel={(option: any) => option?.name ?? ""}
        sx={{
          width: inputWidth,
          maxWidth: isMobile ? 120 : 200,
        }}
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            label=""
            sx={{
              fontSize: FONT_SIZE,
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                py: 0,
                "& input": {
                  py: 0.75,
                },
              },
            }}
            value={inputValue}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props: any, option: any, { selected }: any) => (
          <li
            key={uid()}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              cursor:
                option.isUnderMaintenance && !isSuperUser
                  ? "not-allowed"
                  : "pointer",
              "&:hover": {
                background: option.isUnderMaintenance
                  ? "transparent !important"
                  : theme.customPalette.others.layer_2,
              },
            }}
          >
            <Text
              variant="body1"
              customStyle={{
                paddingLeft: 1,
              }}
            >
              {option.name}
            </Text>
          </li>
        )}
        renderGroup={(params) => {
          const account = accounts?.find((c: any) => c?.id === params?.group);
          const shouldShowAccount =
            account?.sites?.length > 1 ||
            (account?.sites?.length === 1 &&
              account?.sites?.[0]?.name !== account?.name);

          return (
            <Box key={uid()} p={1}>
              {!shouldShowAccount ? (
                <></>
              ) : (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    padding: "2px 0",
                  }}
                >
                  <Box
                    sx={{ width: 16, height: 16 }}
                    component="img"
                    alt=""
                    src={`/${theme.palette.mode}/others/account.svg`}
                  />
                  <Text variant="body2">
                    {accounts?.find((c: any) => c?.id === params?.group)?.name}
                  </Text>
                </Stack>
              )}

              <Box>{params.children}</Box>
              <Divider />
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default SiteSelector;
